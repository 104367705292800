import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Button, message } from "antd";
import React, { useState, useEffect } from "react";
import * as util from "../utils";
import { getMachineDetailByUuid } from "../services/machine";
import { useTranslation } from "react-i18next";
import { getUserInfo, placeOrder } from "../services/nanwang";
import { useSelector } from "react-redux";

function Home({ className }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [duration, setDuration] = useState(0);
  const [taskName, setTaskName] = useState("");
  const [deviceDetail, setDeviceDetail] = useState({});
  const user = useSelector((state) => state.user);
  const validate = (e, name) => {
    if (name === "taskName") {
      let taskName = e.target.value;
      if (taskName.length > 40) {
        util.showError(t("buy.edit.task.name-max-length"));
        setTaskName("");
        return;
      } else {
        setTaskName(taskName);
      }
    } else if (name === "duration") {
      if (e.target.value !== "") {
        let duration = parseInt(e.target.value);
        if (duration === NaN) {
          util.showError(t("buy.edit.task.duration-number"));
          setDuration(0);
          return;
        } else {
          if (duration < 0) {
            util.showError(t("buy.edit.task.duration-min"));
            setDuration(0);
            return;
          } else if (duration > deviceDetail.MaxDuration) {
            util.showError(t("buy.edit.task.duration-max"));
            setDuration(deviceDetail.MaxDuration);
            return;
          } else {
            setDuration(duration);
            setAmount(duration * deviceDetail.Price);
          }
        }
      } else {
        setDuration(0);
      }
    }
  };
  const onSubmit = async () => {
    if (!taskName) {
      return util.alert(t("buy.edit.task.name-required"));
    }
    if (!duration) {
      return util.alert(t("buy.edit.task.duration-required"));
    }
    setLoading(true);
    let ret = await placeOrderStart({
      duration: duration,
      name: taskName,
    });
    setLoading(false);
    if (ret.Msg !== "success") {
      return util.alert(t(ret.Msg));
    }
    message.success(t("buy.success"));
    await getUserInfo();
    navigate("/myorder");
  };
  async function placeOrderStart(formData) {
    let orderId = new Date().valueOf().toString();
    const encoder = new TextEncoder();
    orderId = encoder.encode(orderId);
    var myUint8Array = new Uint8Array(16);
    myUint8Array.set(orderId);
    orderId = myUint8Array;
    const hexString = Array.from(myUint8Array)
      .map((byte) => ("0" + (byte & 0xff).toString(16)).slice(-2))
      .join("");
    let MachineInfo = {
      UuidShort: deviceDetail.UuidShort,
      GpuCount: deviceDetail.GpuCount,
      Gpu: deviceDetail.Gpu,
      TFLOPS: deviceDetail.TFLOPS,
      Region: deviceDetail.Region,
      RAM: deviceDetail.RAM,
      Reliability: deviceDetail.Reliability,
      Cpu: deviceDetail.Cpu,
      Score: deviceDetail.Score,
      UploadSpeed: deviceDetail.UploadSpeed,
      DownloadSpeed: deviceDetail.DownloadSpeed,
    };
    let result = await placeOrder({
      Uuid: hexString,
      MachineUuid: id,
      Duration: parseInt(formData.duration),
      Metadata: JSON.stringify({
        formData,
        MachineInfo,
      }),
    });
    return result;
  }
  useEffect(() => {
    const init = async () => {
      let detail = await getMachineDetailByUuid(id, false);
      if (detail) {
        setDeviceDetail(detail);
      }
    };
    init();
  }, [id]);
  useEffect(() => {
    document.title = t("BuyMachine");
  }, [i18n.language]);
  useEffect(() => {
    if (deviceDetail) {
      setAmount(duration * deviceDetail.Price || 0);
    }
  }, [duration]);
  return (
    <div className={className}>
      <div className="con">
        <h1 className="title">{t("BuyMachine")}</h1>
        <div className="myform">
          <div className="info-box">
            <div className="info-box-title">{t("buy.edit.task.config")}</div>
            <div className="info-box-body">
              <div className="line">
                <div className="f">
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    {deviceDetail.GpuCount + "x " + deviceDetail.Gpu}
                  </span>
                  <span>{deviceDetail.TFLOPS || "--"} TFLOPS</span>
                </div>
              </div>
              <div className="line">
                <div className="l">
                  <span>RAM</span>
                  <span>{deviceDetail.RAM || "--"}</span>
                </div>
                <div className="r">
                  <span>{t("buy.edit.task.avail_storage")}</span>
                  <span>{deviceDetail.Disk} GB</span>
                </div>
              </div>
              <div className="line">
                <div className="f">
                  <span>CPU</span>
                  <span>{deviceDetail.Cpu || "--"}</span>
                </div>
              </div>
              <div className="line">
                <div className="f">
                  <span>{t("buy.edit.task.duration")}</span>
                  <span>{deviceDetail.MaxDuration}h</span>
                </div>
              </div>
            </div>
          </div>
          <div className="info-box">
            <div className="info-box-title">{t("buy.info.title")}</div>
            <div className="info-box-body">
              <div className="line">
                <div className="f">
                  <span>{t("buy.info.price")}</span>
                  <span>
                    {deviceDetail.Price} {t("Yuan")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="row-txt">{t("buy.edit.task.name")}</div>
            <Input
              value={taskName}
              className="my-input"
              onInput={(e) => validate(e, "taskName")}
              placeholder={`${t("Enter")}${t("buy.edit.task.name")}`}
            />
          </div>
          <div className="form-row">
            <div className="row-txt">{t("buy.info.duration")}</div>
            <Input
              value={duration}
              className="my-input"
              onInput={(e) => validate(e, "duration")}
              placeholder={t("makeoffer.hour")}
            />
          </div>
          <div className="right-txt">
            {t("buy.info.balance")}: {user.Balance} {t("Yuan")}
          </div>
          <div className="color-box">
            <div className="row-txt">{t("buy.info.total")}</div>
            <div className="drow">
              <span className="num">{amount}</span>
              <label>{t("Yuan")}</label>
            </div>
          </div>
          <div className="form-row btn-row">
            <Button
              loading={loading}
              disabled={loading}
              style={{ width: 154 }}
              type="primary"
              className="cbtn"
              onClick={onSubmit}>
              {t("buy.info.confirm")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  display: block;
  overflow: hidden;
  width: 100%;
  background-color: #000;
  color: #fff;
  .mini-btn {
    border: 1px solid #fff;
  }
  .pross-box {
    display: none;
  }
  .none {
    display: none !important;
  }
  .sel-out {
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    padding: 20px 0 10px;
    justify-content: space-between;
    .sel-box {
      width: 270px;
      height: 100px;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      font-size: 14px;
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: center 10px;
      display: block;
      overflow: hidden;
      line-height: 140px;
      text-align: center;
      cursor: pointer;
    }
    .lib {
      color: #dddddd;
      background-color: rgba(32, 32, 32, 1);
      border-color: rgba(121, 121, 121, 1);
      background-image: url(/img/market/lib.svg);
    }
    .lib-curr {
      color: #bae5ee;
      background-color: #000;
      border-color: rgba(186, 229, 238, 1);
      background-image: url(/img/market/lib-curr.svg);
      box-shadow: 0px 0px 20px rgba(186, 229, 238, 0.5137254901960784);
    }
    .docker {
      color: #dddddd;
      background-color: rgba(32, 32, 32, 1);
      border-color: rgba(121, 121, 121, 1);
      background-image: url(/img/market/docker.svg);
    }
    .docker-curr {
      color: #bae5ee;
      background-color: #000;
      border-color: rgba(186, 229, 238, 1);
      background-image: url(/img/market/docker-curr.svg);
      box-shadow: 0px 0px 20px rgba(186, 229, 238, 0.5137254901960784);
    }
  }
  .pross-box1 {
    position: fixed;
    left: calc(50% - 500px);
    top: 100px;
    width: 1000px;
    height: 530px;
    background-color: rgba(0, 0, 0, 1);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(64, 64, 64, 1);
    border-radius: 5px;
    padding: 90px 0px;
    display: flex;
    flex-direction: column;
    z-index: 999;
    .close-btn {
      width: 35px;
      height: 35px;
      display: block;
      overflow: hidden;
      position: absolute;
      top: 4px;
      right: 8px;
      cursor: pointer;
      background-image: url(/img/market/close.svg);
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
    }
    .title {
      font-weight: 700;
      font-size: 72px;
      color: #ffffff;
      text-align: center;
    }
    .desc {
      font-size: 16px;
      color: #ffffff;
      text-align: left;
      line-height: 20px;
      padding: 21px 282px;
    }
    .progress {
      width: 60%;
      display: block;
      margin: 50px auto;
    }
    .skip {
      font-size: 16px;
      text-decoration: underline;
      color: #797979;
      text-align: center;
      line-height: 48px;
      cursor: pointer;
    }
    .recommend {
      font-size: 14px;
      color: #515151;
      text-align: center;
    }
  }
  .con {
    width: 1160px;
    margin: 10px auto;
    padding: 0 20px;
    display: block;
    overflow: hidden;
    .title {
      font-family: Montserrat Bold, Montserrat, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 28px;
      color: #ffffff;
      margin-top: 25px;
      line-height: 70px;
    }
    .tab-bar {
      width: 50%;
      padding: 20px 0;
      .bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        span {
          text-align: center;
          width: 50%;
          display: block;
          overflow: hidden;
          font-size: 14px;
          line-height: 48px;
          cursor: pointer;
          color: #94d6e2;
          .fa-check-circle {
            font-size: 22px;
          }
        }
      }
      .bar1 {
        .l {
          border-bottom: 3px solid rgba(148, 214, 226, 1);
        }
        .r {
          color: #797979;
        }
      }
      .bar2 {
        .l {
          color: #94e2b8;
        }
        .r {
          border-bottom: 3px solid rgba(148, 214, 226, 1);
        }
      }
    }
    .info-box {
      display: block;
      .info-box-title {
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        border-bottom: 1px solid #797979;
        line-height: 48px;
      }
      .info-box-body {
        padding: 5px 18px;
        display: block;
        .line {
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          .f {
            width: 100%;
          }
          span {
            line-height: 24px;
            display: block;
            clear: both;
            font-size: 14px;
          }
          .l {
            width: 50%;
          }
          .r {
            width: 50%;
          }
        }
      }
    }
    .b-box {
      display: block;
      padding: 30px;
      border: 1px solid rgba(121, 121, 121, 1);
      border-radius: 5px;
      margin: 20px 0;
      .row {
        display: block;
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        b {
          font-size: 24px;
        }
      }
    }
    .right-txt {
      display: block;
      overflow: hidden;
      text-align: right;
      line-height: 50px;
      font-size: 14px;
      color: #e0c4bd;
    }
    .color-box {
      border-radius: 5px;
      background-color: #151515;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 19px 20px;
      .row-txt {
        font-size: 16px;
        font-weight: bold;
        line-height: 51px;
      }
      .drow {
        display: flex;
        flex-direction: column;
        span {
          width: 100%;
          font-size: 28px;
          font-weight: bold;
          text-align: right;
        }
        label {
          width: 100%;
          font-size: 13px;
          text-align: right;
          line-height: 30px;
        }
      }
    }
    .btn-row {
      display: block;
      margin: 30px 0;
    }
  }
  .block {
    display: block;
    overflow: hidden;
  }
  .mini-btn {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }
  .ant-btn-primary {
    color: #000;
    height: 50px;
    line-height: 40px;
  }
`;

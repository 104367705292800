import styled from "styled-components";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OrderList from "../components/OrderList";
import { getOrderList, getFilterData } from "../services/order";
import Pager from "../components/pager";

let filter = {};

function Home({ className }) {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValue, setFilterValue] = useState({});
  const user = useSelector((state) => state.user);

  const loadList = async (curr) => {
    if (user.Email) {
      setLoading(true);
      try {
        let res = await getOrderList(curr, filter);
        if (res.total) {
          setTotal(res.total);
        }
        if (res.list) {
          setList(res.list);
        }
      } catch (e) {
        throw e;
      }
      setLoading(false);
    } else {
      setTotal(0);
      setList([]);
    }
  };
  const loadFilterData = () => {
    let res = getFilterData();
    setFilterData(res);
    res.forEach((t) => {
      filter[t.name] = "all";
    });
    setFilterValue(filter);
  };

  useEffect(() => {
    loadFilterData();
    loadList(1);
    document.title = t("order.list");
  }, [user, i18n.language]);

  const onFilter = (v, n) => {
    filter[n] = v;
    setFilterValue(filter);
    setCurrent(1);
    loadList(1);
  };
  const onResetFilter = () => {
    filterData.forEach((t) => {
      filter[t.name] = "all";
    });
    setFilterValue(filter);
    setCurrent(1);
    loadList(1);
  };

  const onPagerChange = (curr) => {
    setCurrent(curr);
    loadList(curr);
  };
  return (
    <div className={className}>
      <div className="con">
        <h1 className="title">{t("order.list")}</h1>
        <div className="filter">
          <span className="txt">{t("market.filter")}</span>
          {filterData.map((t) => {
            return (
              <span className="sel" key={t.name}>
                <Select
                  defaultValue="all"
                  value={filterValue[t.name]}
                  style={{ width: 160 }}
                  data-name={t.name}
                  onChange={(e) => onFilter(e, t.name)}
                  options={t.arr}
                />
              </span>
            );
          })}
          <span className="btn-txt" onClick={onResetFilter}>
            {t("market.reset")}
          </span>
        </div>
        <div className="con-table">
          <OrderList list={list} loading={loading} reloadFunc={loadList} />
          {total > 10 ? (
            <Pager
              current={current}
              total={total}
              pageSize={10}
              onChange={onPagerChange}
              className="pager"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #000;
  color: #fff;
  .pager {
    margin: 0 auto;
    width: 400px;
  }
  .filter {
    padding: 11px 0;
    display: flex;
    flex-direction: row;
    line-height: 30px;
    .txt {
      font-size: 14px;
      line-height: 30px;
      height: 30px;
      display: block;
    }
    .sel {
      padding: 0px 7px;
    }
    .btn-txt {
      font-weight: 700;
      font-size: 14px;
      text-decoration: underline;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .con {
    width: 1200px;
    padding: 0 20px;
    margin: 10px auto;
    display: block;
    .title {
      font-family: Montserrat Bold, Montserrat, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 28px;
      color: #ffffff;
      padding-left: 36px;
      background-image: url(/img/market/2.png);
      background-repeat: no-repeat;
      background-size: 26px;
      background-position: left;
      margin-top: 25px;
    }
  }
  .block {
    display: block;
    overflow: hidden;
  }
`;

import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { getMachineList } from "../services/machine";
import DeviceList from "../components/DeviceList";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Home({ className }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const loadList = async () => {
    if (user.Email) {
      setLoading(true);
      try {
        let res = await getMachineList(true, 1, null);
        res.list.map((item) => (item.loading = false));
        setList(res.list);
      } catch (e) {
        setList([]);
      }
      setLoading(false);
    } else {
      setList([]);
    }
  };
  useEffect(() => {
    loadList();
  }, [user]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = t("mydevice.title");
  }, [i18n.language]);
  return (
    <div className={className}>
      <div className="hold"></div>
      <div className="con">
        <h1 className="title">{t("mydevice.title")}</h1>
        <div className="con-table">
          <DeviceList
            list={list}
            setList={setList}
            isMyDevice={true}
            loading={loading}
            reloadFunc={loadList}
          />
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #000;
  color: #fff;
  .con {
    width: 1200px;
    margin: 10px auto;
    display: block;
    padding: 0 20px;
    .title {
      font-family: Montserrat Bold, Montserrat, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 28px;
      color: #ffffff;
      padding-left: 36px;
      background-image: url(/img/market/2.png);
      background-repeat: no-repeat;
      background-size: 32px;
      background-position: left;
      margin-top: 25px;
    }
    .filter {
      padding: 11px 0;
      display: flex;
      flex-direction: row;
      line-height: 30px;
      .txt {
        font-size: 14px;
        line-height: 30px;
        height: 30px;
        display: block;
      }
      .sel {
        padding: 0px 7px;
      }
      .btn-txt {
        font-weight: 700;
        font-size: 14px;
        text-decoration: underline;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .block {
    display: block;
    overflow: hidden;
  }
  .pager {
    display: flex;
  }
`;

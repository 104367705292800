import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserInfo, withdraw } from "../../services/nanwang";
import * as util from "../../utils";

function Home({ className }) {
  let navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [withdrawForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let amount = Form.useWatch("amount", withdrawForm);
  const handleWithdraw = async (values) => {
    setLoading(true);
    try {
      let res = await withdraw(parseFloat(values.amount));
      if (res.Msg === "success") {
        util.showOK(
          `${t("Successfully Withdraw")}${values.amount} ${t("Yuan")}`
        );
        await getUserInfo();
        withdrawForm.resetFields();
      }
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    document.title = t("balance.withdraw");
  }, [i18n.language]);
  return (
    <div className={className}>
      <div className="withdraw">
        <div className="title">
          <span
            className="arrow-left"
            onClick={() => {
              navigate("/balance");
            }}
          />
          <h3>{t("balance.withdraw")}</h3>
        </div>
        <div className="content">
          <Form
            form={withdrawForm}
            layout="vertical"
            onFinish={handleWithdraw}
            requiredMark={false}>
            <Form.Item
              name="address"
              label={t("balance.alipay")}
              rules={[
                {
                  required: true,
                  message: `${t("Enter")}${t("balance.alipay")}`,
                },
              ]}>
              <Input placeholder={`${t("Enter")}${t("balance.alipay")}`} />
            </Form.Item>
            <Form.Item
              label={t("exchange.openorder.amount")}
              name="amount"
              rules={[
                {
                  required: true,
                  message: `${t("Enter")}${t("exchange.openorder.amount")}`,
                },
                {
                  type: "number",
                  max: user.Balance,
                  min: 0,
                },
              ]}>
              <InputNumber
                placeholder={`${t("Enter")}${t("exchange.openorder.amount")}`}
                addonAfter={t("Yuan")}
                defaultValue={0}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <span style={{ marginTop: "10px", display: "block" }}>
              {t("Available")}: {loading ? "" : user.Balance.toFixed(2)}
              {t("Yuan")}
            </span>
            <Form.Item>
              <div className="received">
                <span style={{ color: "#797979" }}>
                  {t("balance.received")}
                </span>
                <span style={{ color: "white", fontWeight: "700" }}>
                  {amount ? amount : 0} {t("Yuan")}
                </span>
              </div>
            </Form.Item>
            <Form.Item>
              <Button className="confirm" htmlType="submit" loading={loading}>
                {t("buy.info.confirm")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  .withdraw {
    width: 1200px;
    margin: 25px auto;
    background-color: #121212;
  }
  .title {
    border-bottom: 1px solid rgba(57, 57, 57, 1);
    height: 42px;
    display: flex;
    align-items: center;
  }
  h3 {
    padding: 2px;
    margin: 0px;
    color: #dbdbdb;
    font-size: 14px;
  }
  .arrow-left {
    width: 10px;
    height: 10px;
    margin-left: 25px;
    margin-right: 15px;
    background-image: url("/img/arrow_left.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .content {
    width: 100%;
    height: 550px;
    padding: 25px;
    background-color: #121212;
  }
  label {
    font-size: 16px !important;
    font-weight: 700;
    color: white !important;
  }
  .received {
    display: flex;
    justify-content: space-between;
  }
  .confirm {
    width: 154px;
    height: 50px;
    background-color: #94d6e2;
    color: #121212;
    border-color: transparent;
  }
  .confirm:hover {
    color: #121212;
    border-color: transparent;
  }
  #withdraw {
    width: 560px;
  }
`;

import * as util from "../utils";
import request from "../utils/request";
import moment from "moment";
import { getTimeDiff } from "time-difference-js";
import { getMachineList } from "./machine";
import i18n from "../i18n/i18n";

export async function getAllOrderList(pageIndex, filter) {
  try {
    let apiUrl = "/api/order/all";
    let options = {
      data: {
        Page: pageIndex,
        PageSize: 10,
      },
    };
    if (filter) {
      for (let k in filter) {
        let v = filter[k];
        if (v && v !== "all") {
          if (k === "Status") v = parseInt(v);
          options.data[k] = v;
        }
      }
    }
    let ret = await request.post(apiUrl, options);
    if (ret.Msg !== "success") {
      util.alert(ret.msg);
      return null;
    }
    let total = ret.Data.Total;
    let list = ret.Data.List;
    for (let item of list) {
      formatOrder(item);
    }
    let machinList = await getMachineList(false, 1, {});
    machinList = machinList.list;
    for (let item of list) {
      if (!item.MachineUuid) continue;
      let tmp = machinList.find(
        (t) => t.Metadata?.MachineUUID === item.MachineUuid.replace("0x", "")
      );
      if (tmp && item.Metadata && typeof item.Metadata == "object") {
        item.Metadata.MachineInfo = tmp;
      }
    }
    let obj = { list, total };
    return obj;
  } catch (e) {
    return null;
  }
}
export async function getOrderList(pageIndex, filter) {
  try {
    let apiUrl = "/api/order/mine";
    let options = {
      data: {
        Page: pageIndex,
        PageSize: 10,
      },
    };
    if (filter) {
      for (let k in filter) {
        let v = filter[k];
        if (v && v !== "all") {
          if (k === "Status") v = parseInt(v);
          options.data[k] = v;
        }
      }
    }
    let ret = await request.post(apiUrl, options);
    if (ret.Msg !== "success") {
      util.alert(ret.msg);
      return null;
    }
    let total = ret.Data.Total;
    let list = ret.Data.List;
    let res = await getMachineList(false, 1, []);
    let machineList = res.list;
    list.forEach((item) => {
      formatOrder(item);
      const machine = machineList.find(
        (machine) => item.MachineUuid === machine.Uuid
      );
      if (machine) {
        item.Metadata.MachineInfo = machine;
      }
    });
    let obj = { list, total };
    return obj;
  } catch (e) {
    return null;
  }
}
function formatOrder(item) {
  if (item.Metadata) {
    try {
      item.Metadata = JSON.parse(item.Metadata);
      if (item.Metadata?.MachineUUID) {
        item.Uuid = item.Metadata.MachineUUID;
      }
    } catch (e) {
      throw e;
    }
  }
  item.Buyer = util.formatAddress(item.Buyer);
  item.Seller = util.formatAddress(item.Seller);
  if (item.Status === 1) {
    let endTime = moment(item.StartedAt).add(item.Duration, "hours").toDate();
    let result = getTimeDiff(new Date(), endTime);
    item.RemainingTime = result.value + " " + result.suffix;
  } else {
    item.RemainingTime = "--";
  }
  switch (item.Status) {
    case 0:
      item.StatusName = "Preparing";
      break;
    case 1:
      item.StatusName = "Training";
      break;
    case 2:
      item.StatusName = "Completed";
      break;
    case 3:
      item.StatusName = "Failed";
      break;
  }
}
export function getFilterData() {
  let list = [];
  list.push({
    name: "Direction",
    arr: [
      { label: `${i18n.t("All")} ${i18n.t("Orders")}`, value: "all" },
      { label: i18n.t("order.detail.buy"), value: "buy" },
      { label: i18n.t("order.detail.sell"), value: "sell" },
    ],
  });
  list.push({
    name: "Status",
    arr: [
      {
        label: `${i18n.t("All")} ${i18n.t("order.blockchain.status")}`,
        value: "all",
      },
      { label: i18n.t("Preparing"), value: "0" },
      { label: i18n.t("Training"), value: "1" },
      { label: i18n.t("Available"), value: "2" },
      { label: i18n.t("Failed"), value: "3" },
    ],
  });
  return list;
}
export async function getDetailByUuid(uuid) {
  const res = await getOrderList(1, []);
  const list = res.list;
  const detail = list.find((t) => t.Uuid === uuid);
  if (!detail) {
    util.showError("Order detail of " + uuid + " not found.");
    return null;
  }
  return detail;
}

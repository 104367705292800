import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import * as util from "../utils";
import { getMachineDetailByUuid } from "../services/machine";
import { makeOffer } from "../services/nanwang";

function Home({ className }) {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [maxStorage, setMaxStorage] = useState(0);
  const childRef = useRef();
  const init = async () => {
    let detail = await getMachineDetailByUuid(id, true);
    if (detail) {
      setMaxStorage(parseInt(detail.Metadata.DiskInfo.TotalSpace));
    }
  };
  const onSubmit = async (values) => {
    let tprice = parseFloat(values.price);
    let maxDuration = values.duration;
    let disk = parseFloat(values.disk);
    util.loading(true);
    setLoading(true);
    try {
      const res = await makeOffer({
        Uuid: id,
        Price: tprice,
        MaxDuration: maxDuration,
        Disk: disk,
      });
      if (res.Msg === "success") {
        util.showOK(`${t("MakeOffer")}${t("Success")}`);
        setLoading(false);
        util.loading(false);
        navigate("/mydevice/");
      } else {
        util.loading(false);
        setLoading(false);
        util.showError(res.msg);
      }
    } catch (e) {
      util.alert(e.message);
    }
  };
  useEffect(() => {
    init();
  }, [id]);
  useEffect(() => {
    document.title = t("MakeOffer");
  }, [i18n.language]);
  return (
    <div className={className}>
      <div className="hold"></div>
      <div className="con">
        <h1 className="title">{t("makeoffer.title")}</h1>
        <div style={{ width: "600px" }}>
          <Form layout="vertical" onFinish={onSubmit} requiredMark={false}>
            <Form.Item
              name="price"
              label={<span className="subtitle">{t("buy.info.price")}</span>}
              rules={[{ required: true }]}>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                placeholder={`${t("Enter")}${t("price")}`}
              />
            </Form.Item>
            <Form.Item
              name="duration"
              label={
                <span className="subtitle">{t("buy.edit.task.duration")}</span>
              }
              rules={[{ required: true }]}>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                placeholder={`${t("Enter")}${t("buy.edit.task.duration")}`}
              />
            </Form.Item>
            <Form.Item
              name="disk"
              label={
                <span className="subtitle">{t("makeoffer.max_storage")}</span>
              }
              rules={[{ required: true }]}>
              <div>
                <div className="row-title2">
                  {t("buy.edit.task.avail_storage")}: {maxStorage}GB
                </div>
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={`${t("Enter")}${t("makeoffer.max_storage")}`}
                />
              </div>
            </Form.Item>
            <Form.Item style={{ marginTop: "30px" }}>
              <Button
                className="cbtn"
                style={{ padding: "0 30px" }}
                loading={loading}
                disabled={loading}
                htmlType="submit">
                {t("buy.info.confirm")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #000;
  color: #fff;
  .mini-btn {
    border: 1px solid #fff;
  }
  .drow {
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 200px;
    margin-top: 20px;
  }
  .uni {
    position: absolute;
    bottom: 16px;
    font-size: 14px;
    right: 15px;
    color: #fff;
  }
  .row-title2 {
    font-size: 14px;
    color: #e0c4bd;
    line-height: 24px;
    display: block;
    margin-bottom: 10px;
  }
  .con {
    width: 1200px;
    padding: 0 20px;
    margin: 10px auto;
    display: block;
    overflow: hidden;
    .title {
      font-family: Montserrat Bold, Montserrat, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 28px;
      color: #ffffff;
      margin-top: 25px;
      line-height: 70px;
    }
  }
  .block {
    display: block;
    overflow: hidden;
  }
  .mini-btn {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }
  .ant-btn-primary {
    background-color: rgba(148, 214, 226, 1) !important;
    color: #000;
    height: 50px;
    line-height: 40px;
    width: 130px;
  }
  .subtitle {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
    line-height: 20px;
    padding: 14px 0;
  }
  .cbtn {
    color: black;
  }
`;

import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { sha256 } from "js-sha256";
import { getUserInfo, login } from "../services/nanwang.js";
import * as util from "../utils";
import { useState } from "react";

function Login({ className, form, close }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const pwdReg = /^[a-zA-Z0-9!@#$%^&*]+$/;
  const onLogin = async (values) => {
    try {
      const password = sha256(values.pwd);
      setLoading(true);
      const res = await login(values.usr, password);
      if (res?.Code === 1) {
        localStorage.setItem("token", res.Data.Token);
        await getUserInfo();
        setLoading(false);
        util.showOK(t("login.success"));
        close();
      } else {
        setLoading(false);
        switch (res.Msg) {
          case "Wrong password":
            util.showError(t("login.pwd_err"));
            form.setFieldValue("pwd", "");
            break;
          case "This email is not registered":
            util.showError(t("login.not_register"));
            break;
          default:
            break;
        }
      }
    } catch (e) {
      throw e;
    }
  };
  return (
    <Form
      className={className}
      layout="vertical"
      form={form}
      onFinish={onLogin}
      requiredMark={false}>
      <Form.Item
        name="usr"
        label={t("Email")}
        rules={[{ required: true }, { type: "email" }]}>
        <Input placeholder={`${t("Enter")}${t("Email")}`} />
      </Form.Item>
      <Form.Item
        name="pwd"
        label={t("Password")}
        rules={[
          { required: true, min: 8, max: 40 },
          {
            pattern: pwdReg,
            message: t("pwd_reg"),
          },
        ]}>
        <Input type="password" placeholder={`${t("Enter")}${t("Password")}`} />
      </Form.Item>
      <Form.Item>
        <Button className="btn confirm" htmlType="submit" loading={loading}>
          {t("Login")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default styled(Login)`
  .confirm {
    width: 180px;
    margin: 0 auto;
  }
`;

import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    Email: "",
    Balance: 0,
    Frozen: 0,
    UserId: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.Email = action.payload.Email;
      state.Balance = action.payload.Balance;
      state.Frozen = action.payload.Frozen;
      state.UserId = action.payload.UserId;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;

import styled from "styled-components";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Spin, Empty, Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CNY from "./CNY";
import { formatAddress } from "../utils";
import { getMachineDetailByUuid } from "../services/machine";

function Header({ className, list, loading, reloadFunc }) {
  let navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(loading);
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  let id = user.UserId;
  let columnsS = [
    {
      title: t("order.detail.time"),
      width: "14%",
      key: "BuyTime",
      render: (text, record, index) => {
        return (
          <div className="time">
            <div className="y">
              {moment(record.CreatedAt).format("YYYY.MM.DD")}
            </div>
            <div className="h">
              {moment(record.CreatedAt).format("HH:mm:ss")}
            </div>
          </div>
        );
      },
    },
    {
      title: t("buy.edit.task.name"),
      width: "10%",
      key: "TaskName",
      render: (text, record, index) => {
        return record.Metadata?.formData?.name || "--";
      },
    },
    {
      title: t("buy.info.price"),
      width: "14%",
      key: "Price",
      render: (text, record, index) => {
        return (
          <div className="price">
            <CNY width={20} />
            <span>{record.Price}</span>
          </div>
        );
      },
    },
    {
      title: t("order.task.remain"),
      width: "10%",
      key: "RemainingTime",
      render: (text, record, index) => {
        return <div>{record.RemainingTime}</div>;
      },
    },
    {
      title: t("buy.info.total"),
      width: "10%",
      key: "Total",
      render: (text, record, index) => {
        return (
          <div className="total">
            <label>{text}</label>
            <span>
              {record.Seller === formatAddress(id)
                ? record.Buyer === formatAddress(id)
                  ? `${t("order.detail.buy")} & ${t("order.detail.sell")}`
                  : t("order.detail.sell")
                : t("order.detail.buy")}
            </span>
          </div>
        );
      },
    },
    {
      title: t("order.blockchain.status"),
      width: "10%",
      key: "Status",
      render: (text, record, index) => {
        if (record.Status === 0) {
          return <div className="status-1">{t("Training")}</div>;
        } else {
          return (
            <div className={"status-" + record.Status}>
              {t(record.StatusName)}
            </div>
          );
        }
      },
    },
    {
      title: "",
      width: "14%",
      key: "Uuid",
      render: (text, record, index) => {
        return (
          <>
            <div style={{ display: "flex" }}>
              <Button
                className="btn"
                onClick={() => navigate(`/${record.Uuid}/detail`)}>
                {t("order.detail.title")}
              </Button>
              {record.Status !== 2 &&
                record.Buyer === formatAddress(user.UserId) && (
                  <Button
                    className="btn"
                    onClick={async () => {
                      await reloadFunc();
                      const machine = await getMachineDetailByUuid(
                        record.MachineUuid,
                        false
                      );
                      if (record.Status === 0) {
                        return Modal.warning({
                          content: t("Device preparing.Try again later"),
                        });
                      }
                      window.open(
                        `http://${machine.IP}:${machine.Port}/?token=${record.AccessUrl}`
                      );
                    }}>
                    {t("Console")}
                  </Button>
                )}
            </div>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    setColumns(columnsS);
  }, [list, user, i18n.language]);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <div className={className}>
      <table className="mytable">
        <thead>
          <tr>
            {columns.map((c) => {
              return (
                <th key={c.title} style={{ width: c.width }}>
                  {c.title}
                </th>
              );
            })}
          </tr>
        </thead>
        {list.length === 0 || isLoading ? (
          <tbody>
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                {isLoading ? (
                  <div className="spin-box">
                    <Spin size="large" />
                  </div>
                ) : (
                  <Empty
                    description={t("devices.empty")}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {list.map((d, index) => {
              return (
                <tr key={index}>
                  {columns.map((c, i) => {
                    if (c.render) {
                      return (
                        <td style={{ width: c.width }} key={i}>
                          {c.render(d[c.key], d, i)}
                        </td>
                      );
                    } else {
                      return (
                        <td key={i} style={{ width: c.width }}>
                          {d[c.key]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default styled(Header)`
  .btn {
    background-color: rgba(148, 214, 226, 1);
    color: #171717;
    margin: 0 5px;
    border: none;
  }
  .btn:hover {
    color: #171717 !important;
    border: none;
  }
  .btn-disable {
    background-color: #797979 !important;
    color: black !important;
  }
  .spin-box {
    width: 100%;
    height: 50px;
    padding: 100px 0;
    display: block;
    overflow: hidden;
    text-align: center;
  }
  .price {
    display: flex;
    clear: both;
    flex-direction: row;
    align-items: center;
    img {
      width: 20px;
    }
    span {
      font-size: 14px;
      color: #ffffff;
      line-height: 20px;
      margin-left: 5px;
    }
  }
  .mytable {
    display: table;
    background-color: #222;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    .link {
      color: #fff;
      cursor: pointer;
    }
    .btn-link {
      color: #fff;
      cursor: pointer;
      text-decoration: underline;
    }
    th {
      background-color: #151515;
      color: #fff;
      height: 37px;
      line-height: 18px;
      text-align: left;
      padding: 8px 10px;
      font-weight: normal;
    }
    tr td {
      border-bottom: 1px solid #1a1a1a;
      border-collapse: collapse;
      padding: 20px 10px;
      overflow: hidden;
    }
    tr:last-children {
      td {
        border-bottom: none;
      }
    }
  }
  .total {
    label {
      display: block;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      color: #ffffff;
      text-align: left;
    }
    span {
      padding: 2px 8px;
      background-color: #000;
      color: #797979;
      font-size: 12px;
      border-radius: 6px;
      text-align: center;
    }
  }
  .status-0 {
    color: #797979;
  }
  .status-1 {
    color: #faffa6;
  }
  .status-2 {
    color: #bdff95;
  }
  .status-3 {
    color: #ffb9b9;
  }
`;

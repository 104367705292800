import store from "../store";
import request from "../utils/request";
import { setUser } from "../store/features/userSlice";

// 发送验证码
export const sendCode = async (email) => {
  let options = {
    data: {
      Email: email,
    },
  };
  try {
    let res = await request.post("/api/user/email-code", options);
    if (res.Code === 0) {
      throw res.Msg;
    }
    return res;
  } catch (e) {
    throw e;
  }
};

// 注册
export const register = async (email, code, password) => {
  let options = {
    data: {
      Email: email,
      Code: code,
      Password: password,
    },
  };
  try {
    let res = await request.post("/api/user/register", options);
    return res;
  } catch (e) {
    throw e;
  }
};

// 登录
export const login = async (email, password) => {
  let options = {
    data: {
      Email: email,
      Password: password,
    },
  };
  try {
    let res = await request.post("/api/user/login", options);
    return res;
  } catch (e) {
    throw e;
  }
};

// 获取用户信息
export const getUserInfo = async () => {
  try {
    let res = await request.get("/api/user/info");
    if (res.Data) {
      localStorage.setItem("user", JSON.stringify(res.Data));
      store.dispatch(setUser(res.Data));
      return res.Data;
    }
  } catch (e) {
    throw e;
  }
};

// 充值
export const deposite = async (amount) => {
  let options = {
    data: {
      Amount: amount,
      PaymentType: "Alipay",
    },
  };
  try {
    let res = await request.post("/api/assets/deposit", options);
    return res;
  } catch (e) {
    throw e;
  }
};

// 支付宝确认
export const notify = async (id) => {
  try {
    let res = await request.post("/api/assets/bill", {
      data: { BillID: id },
    });
    return res;
  } catch (e) {
    throw e;
  }
};

// 提现
export const withdraw = async (amount) => {
  let options = {
    data: {
      Amount: amount,
    },
  };
  try {
    let res = await request.post("/api/assets/withdraw", options);
    return res;
  } catch (e) {
    throw e;
  }
};

// 机器上架
export const makeOffer = async (data) => {
  try {
    let res = await request.post("/api/machine/make-offer", { data });
    return res;
  } catch (e) {
    throw e;
  }
};

// 机器下架
export const cancelOffer = async (uuid) => {
  try {
    let res = await request.post("/api/machine/cancel-offer", {
      data: { Uuid: uuid },
    });
    return res;
  } catch (e) {
    throw e;
  }
};

// 机器租赁
export const placeOrder = async (data) => {
  try {
    let res = await request.post("/api/order/add", { data });
    return res;
  } catch (e) {
    throw e;
  }
};

// 延长租赁时长
export const renewOrder = async (data) => {
  try {
    let res = await request.post("/api/order/renew", { data });
    return res;
  } catch (e) {
    throw e;
  }
};

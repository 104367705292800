import * as util from "../utils";
import request from "../utils/request";

import i18n from "../i18n/i18n";

export async function getMachineDetailByUuid(uuid, isMine) {
  let res = await getMachineList(isMine, 1, {});
  let obj = res.list;
  if (!obj) {
    return null;
  }
  return obj.find((t) => t.Uuid === uuid);
}

export async function getFilterData() {
  let apiUrl = "/api/machine/filter";
  let ret = await request.post(apiUrl);
  if (ret.Msg !== "success") {
    util.alert(ret.msg);
    return null;
  }
  let list = [];
  for (let k in ret.Data) {
    let arr = ret.Data[k].map((t) => {
      return {
        label: i18n.t(t),
        value: t,
      };
    });
    arr.unshift({
      label: `${i18n.t("All")} ${i18n.t(k)}`,
      value: "all",
    });
    list.push({
      name: k,
      arr,
    });
  }
  list.push({
    name: "OrderBy",
    arr: [
      { label: i18n.t("Default"), value: "all" },
      { label: i18n.t("Price(ASC)"), value: "price" },
      { label: i18n.t("Price(DESC)"), value: "price DESC" },
      { label: "TFLOPS", value: "tflops DESC" },
      { label: i18n.t("score"), value: "score DESC" },
      { label: i18n.t("reliability"), value: "reliability" },
    ],
  });
  return list;
}

export async function getMachineList(isMine, pageIndex, filter) {
  try {
    let obj;
    let apiUrl = isMine ? "/api/machine/mine" : "/api/machine/market";
    let options = {
      data: {
        Page: pageIndex,
        PageSize: 10,
      },
    };
    if (filter) {
      for (let k in filter) {
        let v = filter[k];
        if (v && v !== "all") {
          options.data[k] = v;
        }
      }
    }
    let ret = await request.post(apiUrl, options);
    if (ret.Msg !== "success") {
      util.alert(ret.msg);
      return null;
    }
    let total = ret.Data.Total;
    let list = ret.Data.List;
    for (let item of list) {
      formatMachine(item);
    }
    obj = { list, total };
    return obj;
  } catch (e) {
    return null;
  }
}
function formatMachine(item) {
  try {
    if (item.Metadata && typeof item.Metadata == "string") {
      item.Metadata = JSON.parse(item.Metadata);
    }
    item.Addr = util.formatAddress(item.Owner);
    item.Score = item.Metadata?.Score?.toFixed(2);
    if (item.Metadata?.MachineUUID) {
      item.UuidShort = item.Metadata.MachineUUID.slice(-10);
      item.Uuid = item.Metadata.MachineUUID;
    }
    item.Cpu = item.Metadata?.CPUInfo?.ModelName;
    item.RAM = item.Metadata?.InfoMemory?.RAM.toFixed(0) + "GB" || "0GB";
    item.AvailHardDrive = item.Metadata?.DiskInfo?.TotalSpace.toFixed(0) + "GB";
    item.UploadSpeed = item.Metadata?.SpeedInfo?.Upload;
    item.DownloadSpeed = item.Metadata?.SpeedInfo?.Download;
    item.StatusName =
      item.Status === 0
        ? "Available"
        : item.Status === 1
        ? "Listing"
        : "Training";
    if (item.CompletedCount + item.FailedCount <= 0) {
      item.Reliability = "--";
    } else {
      item.Reliability =
        parseInt(
          (item.CompletedCount * 100) / (item.CompletedCount + item.FailedCount)
        ) + "%";
    }
    item.TFLOPS = item.Tflops;
    item.IP = item.Metadata?.Ip?.ip;
    item.Port = item.Metadata.Ip?.port;
  } catch (e) {
    throw e;
  }
}

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Spin, Empty, Button, Tag } from "antd";
import React, { useState, useEffect } from "react";
import * as util from "../utils";
import CNY from "./CNY";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { cancelOffer } from "../services/nanwang";

function Header({ className, list, setList, isMyDevice, loading, reloadFunc }) {
  let navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const onCancel = async (row) => {
    let id = row.Uuid;
    if (!id) {
      return util.showError("id not found");
    }
    try {
      const res = await cancelOffer(id);
      if (res.Msg === "success") {
        row.loading = false;
        setList([...list]);
        reloadFunc();
        return util.showOK(`${t("CancelOffer")}${t("Success")}`);
      }
    } catch (e) {
      throw e;
    }
  };
  const formatStatus = (status) => {
    if (isMyDevice) {
      return status === 0 ? t("makeoffer.title") : t("devices.unlist");
    } else {
      return status === 2 ? t("Rented") : t("devices.select");
    }
  };
  let columnsS = [
    {
      title: isMyDevice ? t("devices.device") : t("devices.provider"),
      width: "12%",
      key: "addr",
      render: (text, record, index) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "80px",
              }}>
              <div>
                <div className="item">
                  <span style={{ color: "#efc6ff", fontSize: "20px" }}>
                    {record.Score || "--"}
                  </span>
                  <label>CPS</label>
                </div>
                <div className="item">
                  <span style={{ color: "#e0c4bd", fontSize: "20px" }}>
                    {record.Reliability || "--"}
                  </span>
                  <label>{t("devices.reliability")}</label>
                </div>
              </div>
              {isMyDevice ? (
                <Tag
                  style={{ height: "24px" }}
                  color={
                    record.Status === 0
                      ? "cyan"
                      : record.Status === 1
                      ? "success"
                      : "warning"
                  }>
                  {t(record.StatusName)}
                </Tag>
              ) : (
                <div className="status" />
              )}
            </div>
            <hr className="line" />
            <div>
              <div className="global">
                <img src="/img/market/global.svg" alt="global" />
                <span>{t(record.Region)}</span>
              </div>
              <div className="ip">
                <span>{record.Metadata?.LocationInfo?.query}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: t("buy.edit.task.config"),
      width: "18%",
      key: "Algorithm",
      render: (text, record, index) => {
        return (
          <>
            <div style={{ height: "80px" }}>
              <div className="gpu">{record.GpuCount + "x " + record.Gpu}</div>
              <div className="cpu">{record.Cpu && `#${record.Cpu}`}</div>
            </div>
            <hr className="line" />
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "36px",
                }}>
                <div className="item">
                  <span>{record.Tflops || "--"}</span>
                  <label>TFLOPS</label>
                </div>
                <div className="item">
                  <span>
                    {parseInt(record.Metadata?.InfoMemory?.RAM)
                      ? `${parseInt(record.Metadata.InfoMemory.RAM)}GB`
                      : "--"}
                  </span>
                  <label>RAM</label>
                </div>
                <div className="item">
                  <span>
                    {record.Disk ||
                      parseInt(record.Metadata.DiskInfo.TotalSpace)}
                    GB
                  </span>
                  <label>{t("buy.edit.task.avail_storage")}</label>
                </div>
              </div>
              <div className="detail">
                <span>
                  {t("buy.edit.task.duration")}: {record.MaxDuration}h
                </span>
                <span>
                  <img src="/img/market/u26.svg" alt="" />{" "}
                  {record.UploadSpeed || "-- Mbit/s"}
                </span>
                <span>
                  <img src="/img/market/u26.svg" alt="" />{" "}
                  {record.DownloadSpeed || "-- Mbit/s"}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: t("buy.info.price"),
      width: "10%",
      key: "Price",
      render: (text, record, index) => {
        return (
          <div className="price">
            <CNY width={20} />
            <span>{record.Price}</span>
          </div>
        );
      },
    },
    {
      title: "",
      width: "10%",
      key: "Id",
      render: (text, record, index) => {
        return (
          <Button
            loading={record.loading}
            disabled={record.loading || !user.Email || record.Status === 2}
            className={
              !user.Email || record.StatusName === "Training"
                ? "mini-btn Disabled"
                : isMyDevice
                ? "mini-btn status" + record.Status
                : "mini-btn status0"
            }
            onClick={() => {
              if (!isMyDevice) {
                return navigate(`/${record.Uuid}/buy`);
              }
              if (record.Status === 0) {
                return navigate(`/${record.Uuid}/makeoffer`);
              }
              if (record.Status === 1) {
                record.loading = true;
                setList([...list]);
                return onCancel(record);
              }
            }}>
            {formatStatus(record.Status)}
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    setColumns(columnsS);
  }, [list, user, i18n.language]);
  return (
    <div className={className}>
      <table className="mytable">
        <thead>
          <tr>
            {columns.map((c) => {
              return (
                <th key={c.title} style={{ width: c.width }}>
                  {c.title}
                </th>
              );
            })}
          </tr>
        </thead>
        {(list && list.length === 0) || loading ? (
          <tbody>
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                {loading ? (
                  <div className="spin-box">
                    <Spin size="large" />
                  </div>
                ) : (
                  <Empty
                    description={t("devices.empty")}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {list &&
              list.map((d, index) => {
                return (
                  <tr key={index}>
                    {columns.map((c, i) => {
                      if (c.render) {
                        return (
                          <td style={{ width: c.width }} key={i}>
                            {c.render(d[c.key], d, i)}
                          </td>
                        );
                      } else {
                        return (
                          <td key={i} style={{ width: c.width }}>
                            {d[c.key]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default styled(Header)`
  .line {
    border: 1px solid #222;
  }
  .spin-box {
    width: 100%;
    height: 50px;
    padding: 100px 0;
    display: block;
    overflow: hidden;
    text-align: center;
  }
  .mytable {
    display: table;
    background-color: #222;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    overflow: hidden;
    .link {
      color: #fff;
      cursor: pointer;
    }
    .btn-link {
      color: #fff;
      cursor: pointer;
      text-decoration: underline;
    }
    th {
      background-color: #151515;
      color: #fff;
      height: 40px;
      line-height: 40px;
      text-align: left;
      padding: 0 10px;
      font-weight: normal;
    }
    tr td {
      border-bottom: 1px solid #1a1a1a;
      border-collapse: collapse;
      padding: 10px;
      overflow: hidden;
    }
    tr:last-children {
      td {
        border-bottom: none;
      }
    }
  }
  .price {
    display: flex;
    clear: both;
    flex-direction: row;
    align-items: center;
    img {
      width: 24px;
    }
    span {
      font-size: 20px;
      color: #ffffff;
      line-height: 20px;
      margin-left: 5px;
      font-weight: bold;
    }
  }
  .item {
    span {
      display: block;
    }
    label {
      color: #6e6e6e;
      font-size: 12px;
    }
  }
  .status {
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
  }
  .Disabled {
    background-color: #6e6e6e;
  }
  .status0 {
    background-color: #94d6e2;
  }
  .status1 {
    background-color: rgba(255, 185, 185, 1);
  }
  .global {
    margin: 10px 0;
    img {
      width: 24px;
      margin-right: 10px;
    }
  }
  .ip {
    height: 19px;
  }
  .gpu {
    font-size: 24px;
    color: #ffffff;
    line-height: 20px;
  }
  .cpu {
    font-size: 14px;
    color: #d7ff65;
    background-image: url(/img/market/u70.svg);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left;
    text-indent: 20px;
    margin-top: 10px;
    line-height: 40px;
  }
  .detail {
    font-size: 12px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    span {
      display: block;
      min-width: 96px;
      text-align: left;
    }
  }
  .mini-btn {
    color: black;
    width: 100px;
    border: none;
  }
  .mini-btn:hover {
    border: none !important;
    color: black !important;
  }
`;

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Modal, message, Button, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { setUser } from "../store/features/userSlice";
import Login from "./Login";
import Register from "./Register";
import { getUserInfo } from "../services/nanwang";

function Header({ className }) {
  let navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [registerForm] = useForm();
  const [loginForm] = useForm();
  const [loginDialog, setLoginDialog] = useState(false);
  const [logining, setLogining] = useState(true);
  const operation = [
    {
      label: t("Logout"),
      key: "1",
    },
    {
      label: t("buy.info.balance"),
      key: "2",
    },
  ];
  const handleDropdownClick = (e) => {
    if (e.key === "1") {
      dispatch(setUser({}));
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      message.info(t("Logout"));
    } else {
      navigate("/balance");
    }
  };
  const items = [
    {
      key: "zh",
      label: (
        <span
          onClick={() => {
            i18n.changeLanguage("zh-CN");
          }}>
          简体中文
        </span>
      ),
    },
    {
      key: "en",
      label: (
        <span
          onClick={() => {
            i18n.changeLanguage("en-US");
          }}>
          English
        </span>
      ),
    },
  ];
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUserInfo();
    }
  }, []);
  useEffect(() => {
    if (loginDialog) {
      if (logining) {
        registerForm.resetFields();
      } else {
        loginForm.resetFields();
      }
    } else {
      registerForm.resetFields();
      loginForm.resetFields();
      setLogining(true);
    }
  }, [logining, loginDialog]);
  return (
    <div className={className}>
      <div className="con">
        <img
          className="logo"
          src="/img/logo-white.png"
          onClick={() => navigate("/")}
          style={{
            width: "140px",
          }}
          alt="logo"
        />
        <div className="content-nav">
          <span onClick={() => navigate("/market")}>{t("header.0")}</span>
          <span onClick={() => navigate("/mydevice")}>{t("header.1")}</span>
          <span onClick={() => navigate("/myorder")}>{t("header.2")}</span>
        </div>
        <div className="right-btn">
          <div className="language">
            <Dropdown menu={{ items }} placement="bottom" trigger={["click"]}>
              <span
                style={{
                  display: "block",
                  width: "24px",
                  height: "24px",
                  backgroundImage: "url('/img/market/global.svg')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  backgroundPosition: "center",
                  cursor: "pointer",
                }}
              />
            </Dropdown>
          </div>
          {user.Email ? (
            <Dropdown
              menu={{
                items: operation,
                onClick: handleDropdownClick,
              }}
              placement="bottom">
              <Button>{user.Email}</Button>
            </Dropdown>
          ) : (
            <Button onClick={() => setLoginDialog(true)}>
              {t("Login")} / {t("Register")}
            </Button>
          )}
        </div>
      </div>
      <Modal
        open={loginDialog}
        onCancel={() => setLoginDialog(false)}
        className={className}
        getContainer={false}
        footer={[]}
        width={1000}>
        <div className="tabs">
          <span
            onClick={() => setLogining(true)}
            style={{ fontWeight: logining ? "bold" : "normal" }}>
            {t("Login")}
          </span>
          <span
            onClick={() => setLogining(false)}
            style={{ fontWeight: !logining ? "bold" : "normal" }}>
            {t("Register")}
          </span>
        </div>
        {logining ? (
          <Login close={() => setLoginDialog(false)} form={loginForm} />
        ) : (
          <Register login={() => setLogining(true)} form={registerForm} />
        )}
      </Modal>
    </div>
  );
}

export default styled(Header)`
  width: 100%;
  height: 56px;
  line-height: 56px;
  background-color: #121212;
  display: block;
  .wallet-adapter-button-trigger {
    background-color: #121212 !important;
    margin-top: 8px !important;
  }
  .user-header {
    width: 36px;
    height: 36px;
    display: block;
    overflow: hidden;
  }
  .con {
    width: 1200px;
    background-color: #121212;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
    height: 56px;
    .logo {
      margin-left: 20px;
      cursor: pointer;
    }
    .content-nav {
      width: 600px;
      margin: 0 auto;
      height: 56px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      span {
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .right-btn {
      margin-right: 20px;
      position: relative;
      top: 0;
      display: flex;
      justify-content: space-around;
    }
  }
  .title {
    text-align: center;
    font-size: 48px;
  }
  .confirm {
    display: flex;
    justify-content: center;
  }
  .btn {
    background-color: #94d6e2;
    border: 0;
    color: black;
    &:hover {
      color: black !important;
    }
  }
  .login-btn {
    margin-left: 20px;
    border: 1px solid #94d6e2;
    color: #94d6e2;
  }
  .login-btn:hover {
    border-color: #94d6e2;
    color: #94d6e2;
  }
  .dropdown {
    min-width: 300px;
    text-align: center;
  }
  .tabs {
    width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    span {
      margin: 0 16px;
      font-size: 40px;
      cursor: pointer;
    }
  }
  .language {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getDetailByUuid } from "../services/order";
import { useSelector } from "react-redux";
import { formatAddress } from "../utils";

function Home({ className }) {
  const { id } = useParams();
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { i18n, t } = useTranslation();
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = t("OrderDetail");
  }, [i18n.language]);
  const loadDetail = async () => {
    setLoading(true);
    try {
      let res = await getDetailByUuid(id);
      setRecord(res);
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    loadDetail();
  }, [id]);

  return (
    <div className={className}>
      <div className="hold"></div>
      <div className="con">
        <h1 className="title">{t("order.detail.title")}</h1>
        <div className="d" style={{ width: "70%" }}>
          {loading ? (
            <Spin />
          ) : record && record.Metadata.MachineInfo ? (
            <div className="detail">
              <div className="info-box">
                <div className="info-box-title">
                  {t("buy.edit.task.config")}
                </div>
                <div className="info-box-body">
                  <div className="title2">
                    # {record.Metadata.MachineInfo.UuidShort}
                  </div>
                  <div className="line">
                    <div className="l">
                      <span>
                        {record.Metadata.MachineInfo.GpuCount +
                          "x " +
                          record.Metadata.MachineInfo.Gpu}
                      </span>
                      <span>
                        {record.Metadata.MachineInfo.TFLOPS || "--"} TFLOPS
                      </span>
                    </div>
                    <div className="r">
                      <span>{t("order.detail.region")}</span>
                      <span>{record.Metadata.MachineInfo.Region}</span>
                    </div>
                  </div>
                  <div className="line">
                    <div className="l">
                      <span>RAM</span>
                      <span>{record.Metadata.MachineInfo.RAM}</span>
                    </div>
                    <div className="r">
                      <span>{t("devices.reliability")}</span>
                      <span>{record.Metadata.MachineInfo.Reliability}</span>
                    </div>
                  </div>
                  <div className="line">
                    <div className="l">
                      <span>CPU</span>
                      <span>{record.Metadata.MachineInfo.Cpu}</span>
                    </div>
                    <div className="r">
                      <span>CPS</span>
                      <span>{record.Metadata.MachineInfo.Score}</span>
                    </div>
                  </div>
                  <div className="line">
                    <div className="f">
                      <span>{t("order.detail.speed")}</span>
                      <span>
                        <img
                          src="/img/market/u27.svg"
                          style={{ transform: "rotate(180deg)" }}
                          alt=""
                        />{" "}
                        {record.Metadata.MachineInfo.UploadSpeed || "--"}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <img src="/img/market/u27.svg" alt="" />{" "}
                        {record.Metadata.MachineInfo.DownloadSpeed || "--"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-box">
                <div className="info-box-title">{t("order.task.title")}</div>
                <div className="info-box-body">
                  <div className="title2">{record.Metadata.formData.name}</div>
                  <div className="line">
                    <div className="l">
                      <span>{t("order.task.start")}</span>
                      <span>
                        {record.StartedTime
                          ? new Date(record.StartedTime).toLocaleString()
                          : "--"}
                      </span>
                    </div>
                    <div className="r">
                      <span>{t("order.task.remain")}</span>
                      <span>{record.RemainingTime}</span>
                    </div>
                  </div>
                  <div className="line">
                    <div className="l">
                      <span>{t("buy.estimate.title")}</span>
                      <span>{record.Duration}h</span>
                    </div>
                    <div className="r">
                      <span>{t("buy.info.duration")}</span>
                      <span>{record.Duration}h</span>
                    </div>
                  </div>
                  <div className="line">
                    <div className="l">
                      <span>{t("devices.provider")}</span>
                      <span>{record.Seller}</span>
                    </div>
                  </div>
                  <div className="line">
                    <div className="l">
                      <span>{t("buy.info.price")}</span>
                      <span>
                        {record.Metadata.MachineInfo.Price} {t("Yuan")}
                      </span>
                    </div>
                    <div className="r">
                      <span>{t("buy.info.total")}</span>
                      <span>
                        {record.Total} {t("Yuan")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-box">
                <div className="info-box-title">
                  {t("order.blockchain.title")}
                </div>
                <div className="info-box-body">
                  <div className="line">
                    <div className="l">
                      <span>{t("order.blockchain.hash")}</span>
                      <span>{record.Uuid}</span>
                    </div>
                  </div>
                  <div className="line">
                    <div className="l">
                      <span>{t("order.blockchain.from")}</span>
                      <span>{record.Seller}</span>
                    </div>
                    <div className="r">
                      <span>{t("order.blockchain.to")}</span>
                      <span>{record.Buyer}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="color-box">
                <div className="l">
                  <span>{t("order.blockchain.status")}</span>
                  <label>{t(`${record.StatusName}`)}</label>
                </div>
                <div className="r">
                  {(record.Status === 0 || record.Status === 1) &&
                    record.Buyer === formatAddress(user.UserId) && (
                      <label
                        className="pointer"
                        onClick={() =>
                          navigate(`/${record.Uuid}/extend-duration/`)
                        }>
                        {t("order.blockchain.extend")}
                      </label>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <>{t("order.empty")}</>
          )}
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  display: block;
  background-color: #000;
  color: #fff;
  .is-show {
    bottom: 0 !important;
  }
  .log-box {
    position: fixed;
    left: 0;
    bottom: -2000px;
    display: block;
    overflow: hidden;
    z-index: 999;
    background-color: #0f0f0f;
    width: 100%;
    transition: bottom 0.5s;
    .log-header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #282828;
      .log-header-con {
        width: 1160px;
        padding: 0 20px;
        margin: 0 auto;
        position: relative;
        left: 0;
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        .log-btn {
          position: absolute;
          right: 0;
          top: 3px;
          display: flex;
          width: 88px;
          span,
          label {
            width: 36px;
            height: 18px;
            background-color: #373737;
            display: block;
            margin: 8px 4px;
            cursor: pointer;
            i {
              background-repeat: no-repeat;
              background-size: 11px;
              background-position: center;
              width: 18px;
              height: 18px;
              margin: 0 auto;
              display: block;
              overflow: hidden;
              transition: all 0.5s;
            }
          }
          span i {
            background-image: url(/img/market/reback.svg);
          }
          label i {
            background-image: url(/img/market/close.svg);
          }
          span:hover,
          label:hover {
            /* transform: rotate(360deg); */
            background-color: rgba(91, 91, 91, 1);
          }
        }
      }
    }
    .log-body {
      width: 100%;
      display: block;
      .log-list {
        width: 1160px;
        padding: 20px;
        background-color: #000;
        margin: 50px auto;
        height: 400px;
        display: block;
        overflow: hidden;
        border-radius: 5px;
        .log-item {
          display: block;
          overflow: hidden;
          word-wrap: break-word;
          clear: both;
          font-size: 14px;

          line-height: 24px;
          span {
            padding-right: 10px;
            color: #797979;
          }
          label {
            color: #fff;
            display: block;
            overflow: hidden;
            clear: both;
          }
        }
      }
    }
  }
  .con {
    width: 1160px;
    margin: 10px auto;
    padding: 0 20px;
    display: block;
    overflow: hidden;
    .title {
      font-family: Montserrat Bold, Montserrat, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 28px;
      color: #ffffff;
      margin-top: 25px;
      line-height: 70px;
    }
  }
  .info-box {
    display: block;
    .info-box-title {
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      border-bottom: 1px solid #797979;
      line-height: 48px;
    }
    .info-box-body {
      padding: 5px 18px;
      display: block;
      .title2 {
        line-height: 20px;
        padding: 15px 0 7px;
        font-size: 18px;
        font-weight: bold;
      }
      .line {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        .f {
          width: 100%;
        }
        span {
          line-height: 24px;
          display: block;
          clear: both;
          font-size: 14px;
        }
        .l {
          width: 50%;
        }
        .r {
          width: 50%;
        }
      }
    }
  }
  .b-box {
    display: block;
    padding: 30px;
    border: 1px solid rgba(121, 121, 121, 1);
    border-radius: 5px;
    margin: 20px 0;
    .row {
      display: block;
      line-height: 30px;
      font-size: 14px;
      text-align: center;
      b {
        font-size: 24px;
      }
    }
  }
  .right-txt {
    display: block;
    overflow: hidden;
    text-align: right;
    line-height: 30px;
    font-size: 14px;
  }
  .color-box {
    border-radius: 5px;
    background-color: #151515;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 10px;
    .l {
      display: flex;
      flex-direction: column;
      span {
        font-size: 14px;
        color: #ffffff;
        line-height: 25px;
      }
      label {
        font-size: 18px;
        color: #faffa6;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .r {
      display: flex;
      flex-direction: row;
      align-items: center;
      span,
      .pointer,
      .disable {
        width: 150px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #151515;
        text-align: center;
        margin: 0 5px;
        border-radius: 4px;
      }
      span {
        background-color: #e0c5bd;
      }
      span:hover {
        background-color: #f7dfd8;
      }
      .pointer {
        background-color: rgb(148, 214, 226);
      }
      .pointer:hover {
        background-color: #bae5ee !important;
      }
      .disable {
        cursor: not-allowed;
        background-color: #2f2f2f;
      }
    }
  }
`;

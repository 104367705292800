import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Form, InputNumber, Button, Radio, Modal, message, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { deposite, getUserInfo, notify } from "../../services/nanwang";
import * as util from "../../utils";

function Home({ className }) {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [querying, setQuerying] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [form] = useForm();
  const { i18n, t } = useTranslation();
  const handleDeposite = async (values) => {
    setLoading(true);
    const amount = values.amount;
    try {
      messageApi.loading("正在打开支付链接", 0);
      const res = await deposite(amount);
      if (res.Msg === "success") {
        messageApi.destroy();
        window.location.href = res.Data.URL;
      }
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    if (params.out_trade_no) {
      setQuerying(true);
      const interval = setInterval(async () => {
        const result = await notify(params.out_trade_no);
        if (result.Code === 0) {
          clearInterval(interval);
          setQuerying(false);
          return util.showError("订单获取失败");
        }
        if (result.Data.Bill.TradeStatus !== "WAIT_BUYER_PAY") {
          clearInterval(interval);
          setQuerying(false);
          if (
            result.Data.Bill.TradeStatus === "TRADE_SUCCESS" ||
            result.Data.Bill.TradeStatus === "TRADE_FINISHED"
          ) {
            util.showOK(
              `${t("Successfully Deposite")}${params.total_amount} ${t("Yuan")}`
            );
            navigate("/balance");
          } else if (result.Data.Bill.TradeStatus === "TRADE_CLOSED") {
            util.showError("充值失败，请重试");
            form.resetFields();
          }
        }
      }, 3000);
    }
  }, []);
  useEffect(() => {
    document.title = t("balance.deposite");
  }, [i18n.language]);
  return (
    <div className={className}>
      {contextHolder}
      <div className="deposite">
        <div className="title">
          <span
            className="arrow-left"
            onClick={() => {
              navigate("/balance");
            }}
          />
          <h3>{t("balance.deposite")}</h3>
        </div>
        <div className="content">
          <Form
            layout="vertical"
            onFinish={handleDeposite}
            form={form}
            requiredMark={false}>
            <Form.Item
              label={t("balance.account")}
              name="account"
              rules={[
                {
                  required: true,
                  message: `${t("devices.select")}${t("balance.account")}`,
                },
              ]}
              initialValue="zfb">
              <Radio.Group>
                <Radio.Button value="zfb" className="radio-btn">
                  <span className="zfb" />
                </Radio.Button>
                <Radio.Button value="vx" className="radio-btn" disabled>
                  <span className="vx" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t("exchange.openorder.amount")}
              name="amount"
              rules={[
                {
                  required: true,
                  message: `${t("Enter")}${t("exchange.openorder.amount")}`,
                },
                {
                  type: "number",
                  min: 0,
                },
              ]}>
              <InputNumber
                addonAfter={t("Yuan")}
                min={0}
                max={1000000}
                placeholder={`${t("Enter")}${t("exchange.openorder.amount")}`}
                style={{ width: "30%" }}
              />
            </Form.Item>
            <Form.Item>
              <span>
                {t("buy.info.balance")}: {user.Balance?.toFixed(2)} {t("Yuan")}
              </span>
            </Form.Item>
            <Form.Item>
              <Button className="confirm" loading={loading} htmlType="submit">
                {t("buy.info.confirm")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Spin tip="确认交易结果..." spinning={querying} fullscreen />
    </div>
  );
}

export default styled(Home)`
  .deposite {
    width: 1200px;
    margin: 25px auto;
    background-color: #121212;
  }
  .title {
    border-bottom: 1px solid rgba(57, 57, 57, 1);
    height: 42px;
    display: flex;
    align-items: center;
  }
  h3 {
    padding: 2px;
    margin: 0px;
    color: #dbdbdb;
    font-size: 14px;
  }
  .arrow-left {
    width: 10px;
    height: 10px;
    margin-left: 25px;
    margin-right: 15px;
    background-image: url("/img/arrow_left.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .content {
    width: 100%;
    padding: 25px;
  }
  label {
    font-size: 16px !important;
    font-weight: 700;
    color: white !important;
  }
  .confirm {
    width: 154px;
    height: 50px;
    background-color: #94d6e2;
    color: #121212;
    border-color: transparent;
  }
  .confirm:hover {
    color: #121212;
    border-color: transparent;
  }
  .radio-btn {
    height: auto;
    padding: 10px 20px;
  }
  .vx,
  .zfb {
    display: block;
    width: 32px;
    height: 32px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .vx {
    background-image: url("/img/vx.svg");
  }
  .zfb {
    background-image: url("/img/zfb.svg");
  }
`;

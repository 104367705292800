import i18n from "i18next";
import enUsTrans from "./locales/en-US.json";
import zhCnTrans from "./locales/zh-CN.json";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      "en-US": {
        translation: enUsTrans,
      },
      "zh-CN": {
        translation: zhCnTrans,
      },
    },
    lng: navigator.language,
    fallbackLng: "zh-CN",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;

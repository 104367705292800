export default function CNY({ width = 24 }) {
  return (
    <span
      style={{
        display: "flex",
        width,
        height: width,
        backgroundSize: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginRight: "10px",
        backgroundImage: "url('/img/market/u65.svg')",
        fontSize: width,
        lineHeight: `${width}px`,
        color: "black",
      }}>
      ￥
    </span>
  );
}

import styled from "styled-components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Home({ className }) {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = t("home.introduction");
  }, [i18n.language]);
  return (
    <div className={className}>
      <div className="content">
        <div className="box1">
          <h1>{t("home.introduction")}</h1>
        </div>
        <div className="box2">
          <div className="con">
            <h1>{t("home.buy_computing_power")}</h1>
            <div className="line-box1"></div>
            <div className="line-box2"></div>
            <div className="mini-boxs">
              <div className="img-box">
                <img src="/img/home/box2-1.png" alt="" />
                <span>{t("home.step.0")}</span>
              </div>
              <div className="img-box">
                <img src="/img/home/box2-2.png" alt="" />
                <span>{t("home.step.1")}</span>
              </div>
              <div className="img-box">
                <img src="/img/home/box2-3.png" alt="" />
                <span>{t("home.step.2")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="box-earn">
          <h2>{t("home.earn_sol.title")}</h2>
          <div className="earn-con">
            <div className="earn-img" />
            <div className="earn-text">
              <div>
                {t("home.earn_sol.desc1")}
                <br />
                <br />
                {t("home.earn_sol.desc2")}
              </div>
              <div style={{ marginTop: "20px" }}>
                <a className="download-btn" href="matrixAI" download>
                  Download Client
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="box-intro">
          <div className="mini-boxs-2">
            <div className="left">
              <h2>{t("home.intro.title")}</h2>
              <span>{t("home.intro.desc")}</span>
            </div>
            <div className="right" />
          </div>
        </div>
        <div className="box3">
          <div className="con">
            <h3>{t("home.reason.title")}</h3>
            <div>
              <span
                style={{
                  backgroundImage: "url(/img/home/box3-1.png)",
                  backgroundSize: 64,
                }}>
                <font>{t("home.reason.fast.title")}</font>
                <label>{t("home.reason.fast.desc")}</label>
              </span>
              <span style={{ backgroundImage: "url(/img/home/box3-2.png)" }}>
                <font>{t("home.reason.low_cost.title")}</font>
                <label>{t("home.reason.low_cost.desc")}</label>
              </span>
              <span style={{ backgroundImage: "url(/img/home/box3-3.png)" }}>
                <font>{t("home.reason.proof.title")}</font>
                <label>{t("home.reason.proof.desc")}</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  display: block;
  font-family: Montserrat, Montserrat Bold, Montserrat, sans-serif;

  .content {
    min-width: 1200px;
    display: block;
    overflow: hidden;
  }
  .con {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0 20px;
  }
  .block {
    display: block;
    overflow: hidden;
  }
  .hold {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 56px;
    clear: both;
    background-color: rgb(0, 0, 0);
  }
  .box1 {
    background-image: url(/img/home/u29.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 600px;
    display: block;
    overflow: hidden;
    h1 {
      width: 100%;
      line-height: 100px;
      text-align: center;
      color: #000;
      font-weight: bold;
      font-size: 48px;
      margin-top: 220px;
    }
    .play-btn {
      background-image: url(/img/home/u44.svg);
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 10px;
      width: 150px;
      height: 40px;
      line-height: 40px;
      text-indent: 49px;
      display: block;
      margin: 42px auto;
      background-color: rgba(0, 0, 0, 1);
      border-radius: 5px;
      font-family: Montserrat, sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      text-decoration: none;
    }
    .play-btn:hover {
      background-color: rgb(49 49 49);
    }
  }
  .box2 {
    text-align: center;
    background-color: #000;
    padding: 100px 0;
    color: #fff;
    .con {
      position: relative;
      top: 0;
    }
    h1 {
      text-align: center;
      display: block;
      width: 100%;
      font-size: 40px;
    }
    .line-box1,
    .line-box2 {
      width: 155px;
      height: 2px;
      display: block;
      overflow: hidden;
      position: absolute;
      top: 235px;
      background-image: url(/img/home/box-2-line.svg);
      background-repeat: repeat-x;
      background-position: center;
    }
    .line-box1 {
      left: 22%;
    }
    .line-box2 {
      left: 62%;
    }
    .mini-boxs {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 100px auto;
      justify-content: space-between;
      .img-box {
        width: 25%;
        img {
          width: 114px;
        }
        .box-line {
          width: 75%;
          margin-top: 79px;
        }
        span {
          display: block;
          clear: both;
          height: 80px;
          line-height: 80px;
          font-size: 16px;
          color: #fff;
          text-align: center;
          overflow: hidden;
          font-family: Montserrat, sans-serif;
        }
      }
    }
  }
  .box3 {
    background-color: rgb(146 213 225);
    height: 600px;
    display: block;
    overflow: hidden;
    .con {
      display: block;
      padding: 117px 0;
      h3 {
        font-family: Montserrat Bold, Montserrat, Montserrat, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 40px;
        color: #000000;
        text-align: center;
      }
      div {
        display: flex;
        flex-direction: row;

        span {
          width: 27.33%;
          margin: 0 3%;
          display: flex;
          flex-direction: column;
          background-repeat: no-repeat;
          background-position: top;
          background-size: 84px;
          margin-top: 57px;
          font {
            width: 100%;
            font-family: Montserrat Bold, Montserrat, Montserrat, sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 18px;
            color: #000000;
            text-align: center;
            line-height: 50px;
            margin-top: 122px;
          }
          label {
            font-family: Montserrat, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: #000000;
            text-align: center;
            line-height: 23px;
          }
        }
      }
    }
  }
  .box-earn {
    background-color: rgb(146, 213, 225);
    padding-top: 120px;
    h2 {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 50px;
    }
    .earn-con {
      display: flex;
      height: 370px;
      width: 1100px;
      margin: 0 auto;
      .earn-img {
        width: 490px;
        height: 100%;
        background-image: url("/img/market/u82.png");
        background-size: 490px 370px;
        background-position: center;
        background-repeat: no-repeat;
      }
      .earn-text {
        width: 450px;
        margin-left: 160px;
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
      }
      .download-btn {
        width: 190px;
        height: 40px;
        background: inherit;
        background-color: rgba(0, 0, 0, 1);
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 1);
        border-radius: 5px;
        color: white;
        padding: 5px;
        text-decoration: none;
      }
    }
  }
  .box-intro {
    padding: 90px 0;
    .mini-boxs-2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto;
      padding: 69px 0px 20px;
      .left {
        width: 50%;
        text-align: left;
        padding-top: 100px;
        h2 {
          margin-top: 0;
          margin-bottom: 0.5em;
          font-family: Montserrat Bold, Montserrat, Montserrat, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 40px;
          color: white;
        }
        span {
          font-family: Montserrat, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
          color: #ffffff;
          line-height: 24px;
          max-width: 550px;
          overflow: hidden;
          display: block;
        }
      }
      .right {
        width: 455px;
        height: 455px;
        background-image: url(/img/home/box-2-4.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: right;
        height: 451px;
      }
    }
  }
  .box4 {
    display: block;
    width: 100%;
    height: 600px;
    overflow: hidden;
    background-color: rgba(40, 17, 61, 1);
    .box4-1,
    .box4-2 {
      width: 100%;
      height: 600px;
      display: block;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .box4-1 {
      background-position: left;
      background-image: url(/img/home/box4-1.png);
    }
    .box4-2 {
      background-position: right;
      background-image: url(/img/home/box4-2.png);
      padding: 38px 0;
    }
    .t1 {
      text-align: center;
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: 48px;
      margin-top: 43px;
    }
    .t2 {
      text-align: center;
      line-height: 48px;
      font-weight: 700;
      font-style: normal;
      font-size: 48px;
      color: #c8ff00;
    }
    .t3 {
      width: 300px;
      height: 74px;
      display: block;
      margin: 69px auto 30px;
      background-color: rgba(200, 255, 0, 1);
      border-radius: 20px;
      font-size: 36px;
      color: #28113d;
      text-align: center;
      line-height: 74px;
      font-family: Montserrat, sans-serif;
    }
    .t4 {
      font-size: 14px;
      color: #c8ff00;
      text-align: center;
      width: 100%;
      display: block;
    }
    .t5 {
      width: 240px;
      display: block;
      overflow: hidden;
      height: 200px;
      margin: 15px auto 0;
      background-image: url(/img/home/box4-3.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }
  .box5 {
    background-color: #000;
    display: block;
    padding: 50px 0;
    height: 600px;
    overflow: hidden;
    .con {
      flex-direction: column;
      .t1 {
        font-weight: 700;
        font-size: 40px;
        color: #ffffff;
        text-align: center;
        margin-top: 88px;
        line-height: 95px;
      }
      .pater {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 200px;
        span {
          width: 25%;
          background-repeat: no-repeat;
          background-position: center;
          height: 200px;
          display: block;
          overflow: hidden;
          background-size: 70%;
          margin: 0 4.16%;
        }
        .l1 {
          background-image: url(/img/home/box5-1.png);
        }
        .l2 {
          background-size: 80%;
          background-image: url(/img/home/box5-2.png);
        }
        .l3 {
          background-size: 25%;
          background-image: url(/img/home/box5-3.png);
        }
        .l4 {
          background-image: url(/img/home/box5-4.png);
        }
      }
    }
  }
  .box6 {
    background-color: #000;
    display: block;
    padding: 80px 0 76px;
    .con {
      flex-direction: column;
      .t1 {
        font-weight: 700;
        font-size: 40px;
        color: #ffffff;
        text-align: center;
        font-family: Montserrat Bold, Montserrat, Montserrat, sans-serif;
      }
      .t2 {
        width: 653px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        overflow: hidden;
        display: block;
        line-height: 24px;
        margin: 20px auto;
        font-family: Montserrat, sans-serif;
      }
      .sub-box {
        display: flex;
        flex-direction: row;
        width: 700px;
        overflow: hidden;
        margin: 50px auto;
        input {
          width: 560px;
          height: 50px;
          padding: 2px 10px 2px 10px;
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          color: #ffffff;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 5px;
          margin-right: 10px;
          outline: none;
        }
        span {
          width: 130px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background-color: rgba(148, 214, 226, 1);
          border-width: 1px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 1);
          border-radius: 5px;
          font-family: Montserrat, sans-serif;
          color: #070706;
          font-size: 14px;
          cursor: pointer;
        }
        span:hover {
          background-color: rgba(186, 229, 238, 1);
        }
      }
    }
  }
`;

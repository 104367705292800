import "./App.css";
import Menu from "./components/Menu";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Home from "./views/Home";
import Buy from "./views/Buy";
import Market from "./views/Market";
import MakeOffer from "./views/MakeOffer";
import OrderDetail from "./views/OrderDetail";
import MyDevice from "./views/MyDevice";
import MyOrder from "./views/MyOrder";
import ExtendDuration from "./views/ExtendDuration";
import Balance from "./views/balance/Balance";
import Deposite from "./views/balance/Deposite";
import Withdraw from "./views/balance/Withdraw";
import { useTranslation } from "react-i18next";
import zhCN from "antd/locale/zh_CN";
import enUS from "antd/locale/en_US";
import { ConfigProvider, theme } from "antd";
let tout = "";

function App() {
  const [isHome, setIsHome] = useState(true);
  const [locale, setLocale] = useState(enUS);
  const { i18n } = useTranslation();
  useEffect(() => {
    tout = setInterval(function () {
      let tmp = window.location.pathname === "/";
      if (tmp !== isHome) {
        setIsHome(tmp);
      }
    }, 300);
    return () => {
      clearInterval(tout);
    };
  }, []);
  useEffect(() => {
    if (i18n.language === "en-US") {
      setLocale(enUS);
    } else if (i18n.language === "zh-CN") {
      setLocale(zhCN);
    }
  }, [i18n.language]);
  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
        locale={locale}>
        {isHome ? (
          <Header className="page-header" />
        ) : (
          <Menu className="page-header" />
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/market" element={<Market />} />
          <Route path="/mydevice" element={<MyDevice />} />
          <Route path="/myorder" element={<MyOrder />} />
          <Route path="/balance" element={<Balance />} />
          <Route path="/deposite" element={<Deposite />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/:id/makeoffer" element={<MakeOffer />} />
          <Route path="/:id/detail" element={<OrderDetail />} />
          <Route path="/:id/buy" element={<Buy />} />
          <Route path="/:id/extend-duration" element={<ExtendDuration />} />
        </Routes>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;

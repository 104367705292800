import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Input, Form, Button, message } from "antd";
import { useState, useEffect } from "react";
import { register, sendCode } from "../services/nanwang";
import * as util from "../utils";
import { sha256 } from "js-sha256";

function Register({ className, form, login }) {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const pwdReg = /^[a-zA-Z0-9!@#$%^&*]+$/;
  const onRegister = async (values) => {
    setLoading(true);
    try {
      const password = sha256(values.pwd);
      const res = await register(values.email, values.code, password);
      if (res?.Code === 1) {
        util.showOK(t("register.success"));
        localStorage.setItem("token", res.Data.token);
        login();
      } else {
        switch (res.Msg) {
          case "This email is registered":
            message.info(t("register.already_register"));
            login();
            break;
          case "Validate Code error":
            util.showError(t("register.code_err"));
            form.setFieldValue("code", "");
            break;
          default:
            break;
        }
      }
    } catch (e) {
      throw e;
    }
    setLoading(false);
  };
  const codeSend = async () => {
    setSending(true);
    const email = form.getFieldValue("email");
    if (!email) {
      setSending(false);
      return util.showError(t("register.no_email"));
    }
    try {
      await sendCode(email);
      setCountdown(60);
    } catch (e) {
      util.showError(t(e));
    }
    setSending(false);
  };
  useEffect(() => {
    if (countdown <= 0) return;
    const timerInterval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [countdown]);
  return (
    <Form
      className={className}
      layout="vertical"
      form={form}
      onFinish={onRegister}
      requiredMark={false}>
      <Form.Item
        name="email"
        label={t("Email")}
        rules={[{ required: true }, { type: "email" }]}>
        <Input type="email" placeholder={`${t("Enter")}${t("Email")}`} />
      </Form.Item>
      <Form.Item
        name="code"
        label={t("Vertification Code")}
        rules={[{ required: true }, { type: "string", max: 6 }]}>
        <Input
          suffix={
            <Button
              className="btn"
              disabled={countdown > 0 || loading}
              loading={sending}
              onClick={codeSend}>
              {countdown > 0 ? countdown : t("register.code_send")}
            </Button>
          }
          placeholder={`${t("Enter")}${t("Vertification Code")}`}
        />
      </Form.Item>
      <Form.Item
        name="pwd"
        label={t("Password")}
        rules={[
          { required: true },
          {
            pattern: pwdReg,
            message: t("pwd_reg"),
          },
        ]}>
        <Input type="password" placeholder={`${t("Enter")}${t("Password")}`} />
      </Form.Item>
      <Form.Item
        name="confirm-pwd"
        label={t("register.Confirm Password")}
        rules={[
          { required: true },
          {
            pattern: pwdReg,
            message: t("pwd_reg"),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("pwd") === value) {
                return Promise.resolve();
              } else {
                return Promise.reject(t("register.pwd_notsame"));
              }
            },
          }),
        ]}>
        <Input type="password" placeholder={t("register.Confirm Password")} />
      </Form.Item>
      <Form.Item className="confirm">
        <Button className="btn confirm" htmlType="submit">
          {t("Register")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default styled(Register)`
  .confirm {
    width: 180px;
    margin: 0 auto;
  }
`;

/**
 * 余额界面
 */
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CNY from "../../components/CNY";
import { useEffect } from "react";

function Home({ className }) {
  let navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    document.title = t("balance.title");
  }, [i18n.language]);
  return (
    <div className={className}>
      <div className="assets">
        <div className="title">
          <h3>{t("balance.title")}</h3>
        </div>
        <div className="content">
          {user.Email ? (
            <>
              <Row className="header">
                <Col span={4}></Col>
                <Col span={5}>{t("Available")}</Col>
                <Col span={5}>{t("balance.locked")}</Col>
                <Col span={5}>{t("buy.info.total")}</Col>
                <Col span={5}>{t("exchange.openorder.operation")}</Col>
              </Row>
              <Row className="token">
                <Col span={4} className="token-container">
                  <CNY />
                  <div>
                    <span className="name">CNY</span>
                    <span className="fullname">{t("RMB")}</span>
                  </div>
                </Col>
                <Col span={5}>{user.Balance.toFixed(2)}</Col>
                <Col span={5}>{user.Frozen.toFixed(2)}</Col>
                <Col span={5}>{(user.Balance + user.Frozen).toFixed(2)}</Col>
                <Col span={5}>
                  <span
                    className="operation"
                    onClick={() => navigate("/deposite")}>
                    {t("balance.deposite")}
                  </span>
                  {/* <span
                    className="operation"
                    onClick={() => navigate("/withdraw")}>
                    {t("balance.withdraw")}
                  </span> */}
                </Col>
              </Row>
            </>
          ) : (
            <div className="not-login">{t("login.login_first")}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default styled(Home)`
  .assets {
    width: 1200px;
    margin: 25px auto;
    background-color: #121212;
  }
  .title {
    border-bottom: 1px solid rgba(57, 57, 57, 1);
    height: 42px;
    display: flex;
    align-items: center;
  }
  h3 {
    padding: 2px;
    padding-left: 20px;
    margin: 0px;
    color: #dbdbdb;
    font-size: 14px;
  }
  .content {
    padding: 0 25px;
  }
  .header {
    height: 40px;
    display: flex;
    align-items: center;
    color: #797979;
  }
  .token {
    height: 70px;
    display: flex;
    align-items: center;
    color: #dbdbdb;
  }
  .token-container {
    display: flex;
    align-items: center;
  }
  .logo {
    display: flex;
    width: 24px;
    height: 24px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
  .csg {
    background-image: url("/img/u49.png");
  }
  .cny {
    background-image: url("/img/market/u65.svg");
    font-size: 24px;
    line-height: 24px;
    color: black;
  }
  .name {
    display: block;
    font-size: 16px;
    line-height: 18px;
  }
  .fullname {
    display: block;
    padding-top: 8px;
    font-size: 10px;
    color: #797979;
  }
  .operation {
    cursor: pointer;
    margin-right: 20px;
  }
  .not-login {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
`;
